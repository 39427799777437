<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="http://simarobot.com" target="_blank">
                        Simarobot
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}}, Desarrollado por
            <a href="http://simarobot.com" target="_blank">Simarobot</a>.
        </div>
    </div>
</footer>
