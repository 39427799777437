import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Workspace } from 'app/models/workspace.model';
import { Observable } from 'rxjs/Observable';
import { Status } from 'app/models/status.model';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService {
  endPointUrl = 'http://code.simarobot.com/php/';
  constructor(private http: HttpClient) {
  }

  updateWorkspace(newWorkspace: Workspace): Observable<Status> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.post<Status>(this.endPointUrl+"updateworkspace.php?id="+newWorkspace.id.toString()+"&name="+newWorkspace.name+"&description="+newWorkspace.description+"&trigger="+newWorkspace.triggerer,{headers:headers,body:newWorkspace.content});
  }

  uploadWorkspace(Workspace: Workspace): Observable<Status> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/xml; charset=utf-8'
    });
    return this.http.post<Status>(this.endPointUrl+"saveworkspace.php?userid="+Workspace.id.toString()+"&name="+Workspace.name+"&description="+Workspace.description+"&trigger="+Workspace.triggerer,{headers:headers,body:Workspace.content});
  }

  downloadWorkspaces(id:number): Observable<HttpResponse<any>>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/xml; charset=utf-8'
    });
    return this.http.get<HttpResponse<Workspace[]>>(this.endPointUrl+"getworkspaces.php?userid="+id.toString(),{headers:headers,observe:'response'});
  }

  deleteWorkspaces(id:number): Observable<Status>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.post<Status>(this.endPointUrl+"deleteworkspace.php?id="+id.toString(),{headers:headers,observe:'response'});
  }
}
