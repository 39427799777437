<div class="logo">
    <div class="container" style="text-align: center;">
        <a routerLink="/" routerLinkActive="active">
            <img src="/assets/img/SimaCodeIcon.svg" height="50px"/>
        </a>
    </div>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" routerLink="/dashboard" style="background-color: #00AFB4; color: white;">
                    <i class="material-icons" style="color: white;">dashboard</i>
                    <p>
                        <span class="d-lg-none d-md-block">simaCode</span>
                    </p>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link" href="#" auto-close="outsideClick" id="navbarDropdownMenuLink" data-toggle="dropdown" style="background-color: #00AFB4; color: white;">
                    <i class="material-icons" style="color: white;">face</i>
                    <p>
                        <span class="d-lg-none d-md-block">Usuario</span>
                    </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" routerLink="/user-profile" routerLinkActive="active" *ngIf="false">Cuenta</a>
                    <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Cerrar Sesión</a>
                </div>
            </li>
        </ul>
    </div>
    <ul class="nav" >
        <li class="nav-item mr-2">
            <a class="nav-link" style="background-color: #00AFB4; color: white; display: inline-flex; width: 225px;">
                <i class="material-icons" style="color: white;" >face</i>
                <p style="width: 150px;">{{ user.name }}</p>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" routerLink="/user-profile" routerLinkActive="active" *ngIf="false">Cuenta</a>
                <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Cerrar Sesión</a>
            </div>
        </li>
    </ul>
    <hr>
    <ul class="nav">
        <li class="nav-item panel-heading collapsed">
            <a class="nav-link panel-title" style="background-color: #00AFB4; color: white; display:inline-flex" data-toggle="collapse" data-target="#devices" aria-expanded="true" aria-controls="devices" role="button" href="#devices">
                <i class="material-icons" style="color: white;" >tablet_android</i>
                <p style="width: 105px;">Dispositivos</p> 
                <i class="material-icons arrow" style="color:white; display: inline;" >expand_more</i>
            </a>
        </li>
        <li id="devices" class=" collapse show">
            <ul style="list-style-type: none; padding-left: 10px; padding-top: 10px; width: 250px;">
                <li *ngFor="let device of devices">
                    <a class="nav-link device" style="padding-top: 0; padding-bottom: 0; background-color: #D59F23; color: white">
                        <input style="margin-right: 10px;" (change)="onDeviceClick($event)" type="checkbox" id="{{device.deviceInfo.id}}" checked>
                        <p>{{device.deviceInfo.name}}</p>
                    </a>
                </li>
            </ul>
        </li>
         <li class="nav-item panel-heading collapsed">
            <a class="nav-link panel-title" style="background-color: #00AFB4; color: white; display:inline-flex" data-toggle="collapse" data-target="#workspaces" aria-expanded="true" aria-controls="devices" role="button" href="#devices">
                <i class="material-icons" style="color: white;" >folder</i>
                <p style="width: 105px;">Workspaces</p>
                <i class="material-icons arrow" style="color:white; display: inline;" >{{'expand_more'}}</i>
            </a>
        </li>
        
        <li id="workspaces" class=" collapse show">
            <ul style="list-style-type: none; padding-left: 10px; padding-top: 10px; width: 250px;">
                <li>
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let workspace of this.dashBoard.workspaces" class="navlink" hideToggle style="background-color: #D59F23; color: white; width: 220px;margin-left: 10px; display: grid; justify-content: center;">
                            <mat-expansion-panel-header style="padding-left: 15px">
                                <mat-panel-title style="color: white;">
                                    <i class="material-icons" style="color: white;" >folder_open</i>
                                    <p style="width: 100%; text-align: center;">{{workspace.name}}</p>
                                    </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div style="display: flex; width: 100%; background-color: #dddddd3d;">
                                <p style="
                                white-space: normal;
                                overflow-wrap: anywhere;
                                color: white;
                                margin-bottom: 10px;
                                padding: 5px;
                                border-radius: 5px;
                                width: 170px;
                                text-align: center;">
                                {{workspace.description}}
                                </p>
                            </div>
                            
                            <div style="width: 100%; justify-content: space-evenly; display: flex;">
                                <button (click)="this.dashBoard.WorkspaceLaunch(workspace)" mat-mini-fab style="box-shadow: none; background-color: transparent;">
                                    <mat-icon>launch</mat-icon>
                                    </button>
                                    <button (click)="this.dashBoard.OnWorkspaceEditButton(workspace)" data-target="#editWorkspaceModal"  data-toggle="modal" data-placement="top" data-whatever="@getbootstrap" mat-mini-fab aria-label="Example icon button with a home icon" style="box-shadow: none; background-color: transparent;">
                                    <mat-icon>edit</mat-icon>
                                    </button>
                                    <button (click)="this.dashBoard.WorkspaceDelete(workspace)" mat-mini-fab style="box-shadow: none; background-color: transparent;">
                                    <mat-icon>delete</mat-icon>
                                    </button>
                            </div>    
                        </mat-expansion-panel>
                    </mat-accordion>
                </li>
            </ul>
        </li> 
    </ul>
    <hr>
    <ul class="nav" >
        <li class="nav-item mr-2">
            <a class="nav-link" routerLink="/user-profile" routerLinkActive="active" *ngIf="false">Cuenta</a>
            <a class="nav-link" routerLink="/login" routerLinkActive="active" style="background-color: #00AFB4; color: white; display: inline-flex; width: 225px;">
                <i class="material-icons" style="color: white;">exit_to_app</i>
                <p style="width: 150px;">Cerrar Sesión</p>
            </a>
        </li> 
    </ul>
</div>