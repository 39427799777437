<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
        <app-sidebar [dashBoard] = "childComponent"></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <div>
            <app-footer></app-footer>
        </div>
    </div>
</div>