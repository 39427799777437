import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) { }

  login(username: string, password: string) {

    const params: HttpParams = new HttpParams()
      .append('email', username)
      .append('pass', password)
      .append('app', '4Sd568wqf4j855J4235GLd56b99');

    const options = {
      params: params
    }
    // console.log(headers, params, options);
    return this.http.get<User>(`http://simarobot.com/sima-profesor/web/LoginInApp`, options);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
  }
}
