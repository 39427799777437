import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { User } from '../models/user.model';
import { Observable } from 'rxjs/Observable';
import { Program } from '../models/program.model';

@Injectable()
export class WebSocketService {

  private url = 'https://ws-simacode.herokuapp.com/'; // 'http://localhost:3000/';
  private user: User = JSON.parse(localStorage.getItem('token'));
  private socket: SocketIOClient.Socket;
  connection: Program;
  enabledDevices: Program[];

  constructor() {
    this.socket = io(`${this.url}`).connect();
  }

  connectRobot() {
    // Connected, let's sign-up for to receive messages for this room
    this.user = JSON.parse(localStorage.getItem('token'));
    this.connection = {
      user: this.user.usuario_id,
      origin: 'dashboard'
    }
    this.socket.emit('user', this.connection);
  }

  sendProgram(commands: string) {
    let devs : string[];
    this.enabledDevices.forEach(enabledDevice => {
      if(devs!=null)
        devs.push(enabledDevice.deviceInfo.id);
      else
        devs = [enabledDevice.deviceInfo.id];
    });
    const program = {
      user: this.user.usuario_id,
      commands: commands,
      devices: devs
    };
    console.log('program', program)
    this.socket.emit('program', program);
  }

  disconnectRobot() {
    this.socket.emit('disconnecting', this.connection);
  }

  getConnectionStatus() {
    return Observable.create((observer: any) => {
      this.socket.on('connected', (connected: boolean) => {
        observer.next(connected);
        console.log('Conectado?:', connected);
      });
    });
  }

  getDeviceInfo() {
    return Observable.create((observer: any) => {
      this.socket.on('user', (user: Program) => {
        if (user.origin === 'APP') {
          observer.next(user);
        }
      })
    })
  }

  handleOnDeviceDisconnect(){
    return Observable.create((observer: any) => {
      this.socket.on('disconnecting',(user: Program) =>{
        if (user.origin === 'APP') {
          observer.next(user);
          console.log(user);
        }
      })
    })
  }

  getConnectedDevices(){
    this.socket.emit('users',this.connection);
  }

}
