import { Component, OnInit, Inject, Input } from '@angular/core';
import { RoutesRecognized } from '@angular/router';
import { WebSocketService } from '../../services/web-socket.service';
import { Program } from 'app/models/program.model';
import Swal from 'sweetalert2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { Workspace } from '../../models/workspace.model'
import { WorkspacesService } from 'app/services/workspaces.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardComponent } from 'app/dashboard/dashboard.component';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/', title: 'simaCode', icon: 'dashboard', class: '' },
    /* { path: '/user-profile', title: 'Usuario', icon: 'face', class: '' }, */
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    menuItems: any[];
    devices: Program[];
    deviceName: string[] = [];
    deviceId: string[] = [];
    public user: User = JSON.parse(localStorage.getItem('token'));
    @Input() dashBoard : any;
    constructor(private fb: FormBuilder,private socket: WebSocketService, private http: HttpClient, private workspacesService: WorkspacesService) { }

    ngOnInit() {
        this.devices = null;
        this.deviceName = null;
        this.deviceId = null;
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.getDeviceList();
        this.handleOnDisconnect();
        this.socket.getConnectedDevices();
        this.dashBoard.getWorkspacesList();
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    getDeviceList() {
        this.socket.getDeviceInfo().subscribe(
            (user: Program) => {
                if(this.devices!=null){
                    if (!this.deviceId.includes(user.deviceInfo.id)) {
                        this.deviceId.push(user.deviceInfo.id);
                        this.deviceName.push('<input type="checkbox" id="'+user.deviceInfo.id+'" > '+user.deviceInfo.name);
                        this.devices.push(user);
                        Swal.fire({
                            title: 'Conectado',
                            text: user.deviceInfo.name+' está conectado, ¡Vamos a programar!',
                            icon: 'success'
                        } as any);
                        if(this.socket.enabledDevices!=null){
                            this.socket.enabledDevices.push(user);
                        }
                    }
                }else{
                    this.deviceId = [user.deviceInfo.id]
                    this.devices = [user];
                    this.deviceName = ['<input type="checkbox" id="'+user.deviceInfo.id+'" > '+user.deviceInfo.name];
                    this.socket.enabledDevices = [user];
                    Swal.fire({
                        title: 'Conectado',
                        text: user.deviceInfo.name+' está conectado, ¡Vamos a programar!',
                        icon: 'success'
                      } as any);
                }
                
            },
            (error: any) => {
                console.error(error);
            }
        );
    }

    onDeviceClick(event){
        let checked = event.target.checked;
        let id = event.target.id;
        if(!checked){
            if(this.socket.enabledDevices!=null){
                for (let index = 0; index < this.socket.enabledDevices.length; index++) {
                    if(this.socket.enabledDevices[index]!=null && this.socket.enabledDevices[index].deviceInfo.id===id){
                        this.socket.enabledDevices.splice(index,1);
                    }
                }
            }
            
        }else
        {
            if(this.devices!=null){
                let dev;
                for (let index = 0; index < this.devices.length; index++) {
                    if(this.devices[index].deviceInfo.id===id){
                        dev = this.devices[index];
                    }
                }    
                if(dev!=null&&!this.socket.enabledDevices.includes(dev)){
                    this.socket.enabledDevices.push(dev);
                }
            }
        }
    }

    handleOnDisconnect(){
        this.socket.handleOnDeviceDisconnect().subscribe(
            (user:Program) => {
                if(this.devices!=null){
                    if(this.deviceId.includes(user.deviceInfo.id)){
                        var i = this.deviceId.indexOf(user.deviceInfo.id);
                        this.deviceId.splice(i,1);
                        this.devices.splice(i,1);
                        this.deviceName.splice(i,1);
                        Swal.fire({
                            title: 'Desconectado',
                            text: user.deviceInfo.name+' se ha desconectado',
                            icon: 'info'
                          } as any);
                        
                        if(this.socket.enabledDevices!=null){
                            for (let index = 0; index < this.socket.enabledDevices.length; index++) {
                                if(this.socket.enabledDevices[index]!=null && this.socket.enabledDevices[index].deviceInfo.id===user.deviceInfo.id){
                                    this.socket.enabledDevices.splice(index,1);
                                }
                            }
                        }
                    }
                }
            }
        )
    }
}
